<template>
    <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
        <div class="animated fadeIn">
            <b-row>
                <b-col lg="12">
                    <transition name="fade">
                        <b-card no-body>
                            <div slot="header">
                                <i class="fa fa-edit"></i> {{ pageName }}
                                <div class="card-header-actions">
                                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                                        <i class="icon-arrow-up"></i>
                                    </b-link>
                                </div>
                            </div>
                            <b-collapse id="collapse1" visible>
                                <b-card-body>
                                    <b-row>
                                        <b-col sm="3">
                                            <b-form-group>
                                                <label>Message Key</label>
                                                <b-form-input v-model="item.messageKey" disabled></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="3">
                                            <Textarea v-model="item.answerText" placeholder="Name"
                                                     :addClass="{'is-invalid': $v.item.answerText.$error}">Answer Text
                                            </Textarea>
                                        </b-col>

                                    </b-row>
                                    <b-row>
                                        <b-col sm="4">
                                            <h3>Image</h3>
                                            <div class="profile-img-upload">
                                                <img v-if="image" :src="image" alt="img" style="width: 150px">
                                                <img v-else src="../../assets/img/no-image.png" alt="img" style="width: 150px">
                                                <input type="file" style="display: none"
                                                       id="imageUpload"
                                                       v-on:input="onImageChoose">
                                                <label for="imageUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span style="cursor: pointer">Change</span>
                                            </span>
                                                </label>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-actions">
                                            <b-button right type="submit" variant="primary" @click="onSubmit">Save
                                            </b-button>
                                            <router-link type="cancel" class="btn btn-secondary" variant="secondary"
                                                         :to="'/answer-messages/list'">Cancel
                                            </router-link>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </transition>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'

    export default {
        name: "AnswerMessagesAddEditPage",
        data() {
            return {
                item: {
                    id: null,
                    messageKey:'',
                    answerText:''
                },
                image: ''
            }
        },
        created() {
            if (!this.$route.params.id || this.$route.params.id <= 0) {
                return
            }
            this.axios.get('answerMessage/item', {params: {id: this.$route.params.id}})
                .then(response => {
                    this.item = response.data.data
                    if (this.item.image) {
                        this.image = this.item.image.imageURL
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data.status === 400) {
                        this.$toastr.error(error.response.data.message)
                    }
                })
        },

        computed: {
            pageName() {
                return this.$route.params.id ? 'Edit Answer Message' : 'Add Answer Message'
            }
        },
        methods: {
            onSubmit() {
                if (this.$v.item.$invalid) {
                    this.$v.$touch()
                    return
                }
                let axios
                if (this.item.id) {
                    axios = this.axios.put('answerMessage/item', this.item)
                } else {
                    axios = this.axios.post('answerMessage/item', this.item)
                }

                axios.then((res) => {
                    if (res.data) {
                        this.$toastr.info('Successfully saved')
                        this.$router.push('/answer-messages/list')
                    } else {
                        this.$toastr.error(res.data.message)
                    }
                })
            },
            onImageChoose (event) {
                this.selectedFile = event.target.files[0]
                const formData = new FormData()
                formData.append('uploadFile', this.selectedFile)

                this.axios.post('upload/file',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                    .then((response) => {
                        if (response.data && response.data.data) {
                            this.item.image = {id: response.data.data.id}
                            this.image = response.data.data.imageURL
                        }
                    })
            },
        },
        validations: {
            item: {
                answerText: {
                    required
                }
            }
        }
    }
</script>

<style scoped>

</style>
